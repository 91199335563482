import { createApp } from 'vue'
import './style.css'
import "element-plus/dist/index.css";
import App from './App.vue'
import router from './router/index'
import { createHead } from '@unhead/vue'
import { pinia } from "@/store";
import i18n from './lang';

const app = createApp(App)
const head = createHead()

app.use(pinia).use(router).use(head).use(i18n).mount("#app");
