<template>
    <div class="user-head" @mouseenter="user = true" @mouseleave="user = false">
        <div class="headimg">
            <img :src="userInfo.userInfo.headImgUrl ? handleAssetsUrl(userInfo.userInfo.headImgUrl) : 'https://fresource.laihua.com/image/profile.png'"
                alt="">
            <p v-if="!userInfo.isFree" :class="`sign${userInfo.isPro ? '6' : '4'}`">
                {{ userInfo.isFree ? 'Free' : userInfo.isPro ? 'Pro' : 'Pro+' }}</p>
        </div>
        <transition name="el-zoom-in-top">
            <ul class="user-wrap" v-show="user">
                <li class="username">
                    <p class="name">{{ userInfo.userInfo.nickname }}</p>
                    <p class="mail">{{ userInfo.userInfo.mailAddr }}</p>
                    <div class="icon">
                        <img v-if="userInfo.isUrladsVip" :src="iconVip" alt="" width="40">
                        <img v-else :src="icon" alt="" width="40">
                        <p class="right">
                            <span v-if="userInfo.isUrladsVip" class="tit">{{ userInfo.userInfo.chartletLevelName
                                }}</span>
                            <span v-else class="tit">{{ userInfo.isFree ? "Free" : userInfo.isPro ? 'Pro' : 'Pro+'
                                }}</span>
                            <span class="time" v-if="!userInfo.isFree">{{ tsFil(userInfo.userInfo.chartletEndDay)
                                }}</span>
                        </p>
                    </div>
                </li>
                <li class="hli" @click="openWin(`${urltoads}/workbench/account`)">
                    <img src="@/assets/img/index-new/set.png" alt="" width="22">
                    {{ $t('head.set') }}
                </li>
                <li class="hli" @click="loginout">
                    <img src="@/assets/img/index-new/log.png" alt="" width="22">
                    {{ $t('head.out') }}
                </li>
            </ul>
        </transition>
    </div>
</template>
<script setup lang="ts">
import { openWin, handleAssetsUrl, tsFil, requireImg } from "@/utils";
import { ref } from "vue";
import { ElMessage } from "element-plus";
import { useLoginStore } from "@/store";
import axios from "axios";

const userInfo = useLoginStore()
const urltoads = import.meta.env.VITE_URLTOADS

const user = ref(false)

const icon = computed(() => {
    return userInfo.isFree ? requireImg("vip/vip1.png") : userInfo.isPro ? requireImg("vip/vip2.png") : requireImg("vip/vip3.png");
})
const iconVip = computed(() => {
    return userInfo.isUrlEnterprise ? requireImg(`vip/vip${userInfo.userInfo.chartletLevel}.png`) : userInfo.isUrlPro ? requireImg("vip/vip2.png") : requireImg("vip/vip3.png");
})

const loginout = async () => {
    const { data } = await axios.put("/media-api/logout");
    if (data.code !== 200) {
        ElMessage.error(data.msg);
        return
    }
    userInfo.changeLogin({})
}
</script>
<style lang="scss" scoped>
.user-head {
    width: 50px;
    height: 50px;
    position: relative;
    background: #fff;

    .headimg {
        width: 100%;
        height: 100%;
        border-radius: 50%;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }

        p {
            width: 42px;
            height: 18px;
            text-align: center;
            line-height: 18px;
            background: #D9F9E6;
            border-radius: 3px 3px 3px 3px;
            font-size: 12px;
            font-family: 'Inter-Medium';
            font-weight: 500;
            color: #009840;
            position: absolute;
            left: 40px;
            // transform: translate(-50%, 0);
            top: -5px;
            z-index: 6;
        }

        .sign0 {
            background: #D9F9E6;
            color: #009840;
        }

        .sign6 {
            background: #EFEEFF;
            color: #8379FF;
        }

        .sign4 {
            background: #F7EDFE;
            color: #BE71FB;
        }
    }

    .user-wrap {
        position: absolute;
        padding-bottom: 10px;
        right: 0;
        top: 64px;
        width: 266px;
        background: #FFFFFF;
        box-shadow: 0px 0px 22px 2px rgba(70, 76, 89, 0.13);
        border-radius: 10px 10px 10px 10px;
        box-sizing: border-box;
        z-index: 9;
        list-style: none;

        .username {
            border-bottom: 1px solid #E7E7E7;
            text-align: left;
            margin-bottom: 10px;
            padding: 22px 18px 30px 24px;

            .name {
                font-size: 18px;
                font-family: 'Inter-Medium';
                font-weight: 500;
                color: #000000;
                line-height: 18px;
                margin-bottom: 10px;
            }

            .mail {
                font-size: 14px;
                font-family: 'Inter-Regular';
                font-weight: 400;
                color: #646464;
                line-height: 14px;
            }

            .icon {
                width: 100%;
                height: 72px;
                background: #FAFAFF;
                border-radius: 6px 6px 6px 6px;
                display: flex;
                align-items: center;
                padding-left: 16px;
                box-sizing: border-box;

                img {
                    margin-right: 8px;
                }

                .right {
                    span {
                        font-family: PingFang SC-Regular;
                        font-weight: 400;
                        font-size: 12px;
                        color: #999999;
                        line-height: 20px;
                        text-align: left;
                        display: block;
                        height: 20px;
                    }

                    .tit {
                        font-family: PingFang SC-Semibold;
                        font-weight: 600;
                        font-size: 16px;
                        color: #333333;
                    }
                }
            }
        }

        .hli {
            width: 100%;
            height: 44px;
            display: flex;
            align-items: center;
            cursor: pointer;
            font-size: 16px;
            font-family: 'Inter-Regular';
            font-weight: 400;
            color: #000000;
            padding: 0 0 0 24px;
            box-sizing: border-box;

            img {
                margin-right: 15px;
            }

            &:hover {
                background: rgba(243, 243, 243, 1);
            }
        }
    }
}
</style>