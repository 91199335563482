import { defineStore } from "pinia";
import axios from "axios";
import { ElMessage } from "element-plus";
interface User{
    chartletLevelType: number,
    level: number,
    levelType: number,
    chartletLevelNewType: number,
    chartletLevel: number,
    levelNewType: number,
    headImgUrl: string,
    nickname: string,
    mailAddr: string,
    chartletEndDay: number,
    chartletLevelName: string
}

const useLoginStore = defineStore("login", {
  state: () => ({
    userInfo: {} as User,
    lang: 'en'
  }),
  getters: {
    loginStatus: (state) => !!Object.keys(state.userInfo).length,
    userLevelType: ({ userInfo: ui }) => ui.chartletLevelType,
    isFree: ({ userInfo: ui }) =>
      ui.level === 0 && ui.levelType === 0 && !ui.chartletLevelNewType,
    // 是否个人 数字人pro版 (包含月度和年度)
    isPro: ({ userInfo: ui }) =>
      ui.chartletLevelNewType
        ? ui.chartletLevelType === 0 &&
          (ui.chartletLevelNewType === 13 || ui.chartletLevelNewType === 14)
        : ui.chartletLevel === 0 &&
          (ui.levelNewType === 13 || ui.levelNewType === 14),

    // 是否企业 数字人pro+版 (包含月度和年度)
    isProPlus: ({ userInfo: ui }) =>
      ui.chartletLevelNewType
        ? ui.chartletLevelType === 2 &&
          (ui.chartletLevelNewType === 15 || ui.chartletLevelNewType === 16)
        : ui.chartletLevel === 0 &&
          (ui.levelNewType === 15 || ui.levelNewType === 16),
    // 是否是新版url to ads会员
    // 149: 数字人Pro版月度会员 154年度
    // 159: 数字人Pro+版月度会员 164年度
    // 174企业版黄金会员, 175企业版铂金会员, 175企业版钻石会员
    isUrladsVip: ({ userInfo: ui }) => ui.chartletLevel == 149 || ui.chartletLevel == 154 || ui.chartletLevel == 159 || ui.chartletLevel == 164 || ui.chartletLevel == 174 || ui.chartletLevel == 175 || ui.chartletLevel == 176,
    // 新版url to ads会员 数字人Pro版月度会员
    isUrlProMonth: ({ userInfo: ui }) => ui.chartletLevel == 149,
    // 新版url to ads会员 数字人Pro版年度会员
    isUrlProYear: ({ userInfo: ui }) => ui.chartletLevel == 154,
    // 新版url to ads会员 数字人Pro版会员
    isUrlPro: ({ userInfo: ui }) => ui.chartletLevel == 154 || ui.chartletLevel == 149,
    // 新版url to ads会员 数字人Pro+版月度会员
    isUrlPlusoMonth: ({ userInfo: ui }) => ui.chartletLevel == 159,
    // 新版url to ads会员 数字人Pro+版年度会员
    isUrlPlusYear: ({ userInfo: ui }) => ui.chartletLevel == 164,
    // 新版url to ads会员 数字人Pro+版会员
    isUrlPlus: ({ userInfo: ui }) => ui.chartletLevel == 159 || ui.chartletLevel == 164,
    // 新版url to ads会员 企业版版会员
    isUrlEnterprise: ({ userInfo: ui }) => ui.chartletLevel == 174 || ui.chartletLevel == 175 || ui.chartletLevel == 176,
  },
  actions: {
    changeLogin(data: any) {
      this.userInfo = Object.keys(data).length ? data : {};
    },
    changeLang(data: string) {
      this.lang = data;
    },
    /**
     * 获取session
     */
    async fetchUserInfo() {
      const { data } = await axios.get("/innaio-api/session");
      if (data.code === 200) {
        this.changeLogin(data.data);
        console.log("==", this.loginStatus);
      } else {
        ElMessage.error(data.msg)
      }
    },
  },
});
export default useLoginStore;
