<script setup lang="ts">
import { useRoute } from "vue-router";

const route = useRoute();

const hVisible = computed(() => {
    return route.path === "/urltovideo";
});

const fVisible = computed(() => {
    let array = ['/privacy', '/privacypolicy', '/termofuse', '/aboutus'];
    return !array.includes(route.path);
});
</script>

<template>
    <div class="lang-wrap">
        <changeLang></changeLang>
    </div>
    <Header v-show="hVisible"></Header>
    <router-view></router-view>
    <Footer v-show="fVisible"></Footer>
</template>

<style lang="scss" scoped>
@import "@/assets/font.css";

body {
    font-family: Inter-Regular, Inter;
}

.lang-wrap {
    position: absolute;
    right: 120px;
    top: 15px;
    z-index: 99;
}
</style>