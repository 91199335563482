import { createI18n } from 'vue-i18n';
import en from '../../public/en.json';
import ja from '../../public/ja.json';
const messages = {
  en,
  ja
};

const i18n = createI18n({
  locale: 'en',
  legacy: false,
  messages
});
export default i18n;
