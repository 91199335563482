import { createRouter, createWebHistory } from "vue-router";
import NotFound from "../pages/NotFound.vue";
import layout from "../layout/index.vue";

export const routes = [
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
  {
    path: "/",
    name: "home",
    redirect: "/",
    component: layout,
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../pages/index.vue"),
      },
      {
        path: "/privacy",
        name: 'privacy',
        component: () => import("../pages/privacy.vue"),
      },
      {
        path: "/privacypolicy",
        name: 'privacypolicy',
        component: () => import("../pages/privacypolicy.vue"),
      },
      {
        path: "/termofuse",
        name: 'termofuse',
        component: () => import("../pages/termofuse.vue"),
      },
      {
        path: "/aboutus",
        name: 'aboutus',
        component: () => import("../pages/aboutus.vue"),
      },
      {
        path: "/urltovideo",
        name: 'urltovideo',
        component: () => import("../pages/urltovideo.vue"),
      },
      {
        path: "/:catchAll(.*)",
        component: NotFound,
      }
    ]
  },
];

console.log("router------->import", import.meta.env.VITE_NODE_ENV);
const pth = import.meta.env.VITE_NODE_ENV === "development" ? "/new" : "/";
const router = createRouter({
  scrollBehavior: () => ({ left: 0, top: 0 }),
  history: createWebHistory(pth),
  routes,
});

// router.beforeEach((to, from, next) => {
//     next()
// })

export default router;
