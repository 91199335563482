export function openWin(url: string){
  const tagA = document.createElement("a");
  tagA.href = url;
  tagA.target = "_blank";
  tagA.click(); // 点击事件
  tagA.remove();
};

export function handleAssetsUrl(url: string){
  if (!url) return;
  const httpReg = /^http/;
  const reg = /^\//;
  const slashHttpReg = /^\/http/;
  const qiniuAssetsUrl = "https://fresource.laihua.com";
  if (httpReg.test(url) || url.indexOf("base64") != -1) {
      // 兼容第三方登录的头像地址为http的情况，替换为https
      return url.replace(/http:/, "https:");
  }
  // 兼容以/开头的绝对路径：/http://xxxxx.com
  if (slashHttpReg.test(url)) {
      return url.substr(1);
  } else if (reg.test(url)) {
      return qiniuAssetsUrl + url;
  } else {
      return qiniuAssetsUrl + "/" + url;
  }
};

/**
 * 处理时间戳
 * 返回格式：yyyy-mm-dd hh:mm
 */
export const tsFil = (ts: string | number | Date, isHou?: Boolean) => {
  if (!ts) return;
  //分钟必须是两位数
  function checkTime(t: number | string) {
    if (t as number < 10) {
      t = "0" + t;
      return t;
    } else {
      return t;
    }
  }
  //浏览器兼容，不能用toLocaleString()
  ts = new Date(Number(ts) * 1000);

  const y = ts.getFullYear();
  const mon = ts.getMonth() + 1;
  const day = ts.getDate();
  const hou = ts.getHours();
  const min = checkTime(ts.getMinutes());

  let time;
  if (isHou) {
    time = `${y}-${mon}-${day} ${hou}:${min}`;
  } else {
    time = `${y}-${mon}-${day}`;
  }
  return time;
};

export function requireImg(url: string) {
  return new URL(`../assets/img/${url}`, import.meta.url).href;
}
