<template>
    <el-popover placement="bottom" :width="160" popper-class="lang-popper" :show-arrow="false" ref="langpopper">
        <template #reference>
            <div class="change-lang">
                <img src="@/assets/img/creation/lang.png" alt="" width="18">
                <p>{{ langText }}</p>
            </div>
        </template>
        <template #default>
            <ul class="lang-ul">
                <li v-for="item in langArr" :key="item.id" @click="changelang(item)"
                    :class="lang == item.id ? 'active' : ''">{{
                        item.text
                    }}</li>
            </ul>
        </template>
    </el-popover>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useLoginStore } from "@/store";
import axios from "axios";

const user = useLoginStore()

const { locale } = useI18n();
const langpopper = ref<HTMLElement | null>(null)
const langText = ref('English')
const lang = ref('en')
const langArr = [
    {
        id: 'en',
        text: 'English',
        key: 'en-US'
    },
    {
        id: 'ja',
        text: '日本語',
        key: 'ja-JP'
    }
]
const changelang = (data: any) => {
    langText.value = data.text
    lang.value = data.id
    user.changeLang(data.id)
    localStorage.setItem('innaio-lang', data.id)
    locale.value = data.id;
    // @ts-ignore
    langpopper.value?.hide()
    setlocale(data.key)
}

onMounted(() => {
    if (localStorage.getItem('innaio-lang')) {
        const language = localStorage.getItem('innaio-lang')
        langText.value = langArr.find(obj => obj.id == language)?.text as string
        lang.value = language as string
        user.changeLang(language as string)
        locale.value = language as string;
    }
})

const setlocale = async (lang: string) => {
    const { data } = await axios.get(`/media-api/?locale=${lang}`);
    if (data.code !== 200) {
        console.log(data, 999)
        return
    }
}
</script>
<style lang="scss" scoped>
.change-lang {
    width: 136px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    cursor: pointer;

    p {
        font-family: PingFang SC-Medium;
        font-weight: 500;
        font-size: 18px;
        color: #42424B;
        margin-left: 8px;
    }

    &:hover {
        box-shadow: 0px 0px 24px 0px rgba(35, 33, 125, 0.1);
    }
}

.lang-popper {
    padding: 10px 0;

    .lang-ul {
        width: 160px;
        padding: 0;

        li {
            list-style: none;
            width: 100%;
            font-family: PingFang SC-Regular;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            height: 40px;
            line-height: 40px;
            padding-left: 16px;
            box-sizing: border-box;
            cursor: pointer;

            &:hover {
                background: #f5f7fa;
            }
        }

        .active {
            color: #3E81FF;
        }
    }
}
</style>